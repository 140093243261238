// TradeTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns';
import Select from 'react-select';
import './TradeTable.css'; // Import the CSS file

process.env.TZ = 'UTC';

const TradeTable = () => {
  const [trades, setTrades] = useState([]);
  const [filteredTrades, setFilteredTrades] = useState([]);

  const [openDateRange, setOpenDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);

  const [closeDateRange, setCloseDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);

  const [profitFilter, setProfitFilter] = useState('all'); // 'all', 'profit', 'loss'
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedSymbols, setSelectedSymbols] = useState([]);

  // Toggles for date pickers
  const [openDatePickerVisible, setOpenDatePickerVisible] = useState(false);
  const [closeDatePickerVisible, setCloseDatePickerVisible] = useState(false);

  // State variables for sorting
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

  // State to track expanded rows
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/profit-loss');
        setTrades(response.data);
        setFilteredTrades(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Get unique states and symbols
  const uniqueStates = [...new Set(trades.map((trade) => trade.state))];
  const uniqueSymbols = [...new Set(trades.map((trade) => trade.symbol))];

  // Options for select components
  const stateOptions = uniqueStates.map((state) => ({ value: state, label: state }));

  // Add "Select All" option to symbol options
  const symbolOptions = [
    { value: 'all', label: 'Select All Symbols' },
    ...uniqueSymbols.map((symbol) => ({ value: symbol, label: symbol })),
  ];

  useEffect(() => {
    let filtered = trades;

    // Filter by openTime
    if (openDateRange[0].startDate && openDateRange[0].endDate) {
      filtered = filtered.filter((trade) => {
        const openTime = new Date(trade.openTime);
        return (
          openTime >= openDateRange[0].startDate &&
          openTime <= openDateRange[0].endDate
        );
      });
    }

    // Filter by closeTime
    if (closeDateRange[0].startDate && closeDateRange[0].endDate) {
      filtered = filtered.filter((trade) => {
        if (trade.closeTime) {
          const closeTime = new Date(trade.closeTime);
          return (
            closeTime >= closeDateRange[0].startDate &&
            closeTime <= closeDateRange[0].endDate
          );
        }
        return false;
      });
    }

    // Profitability filter
    if (profitFilter !== 'all') {
      filtered = filtered.filter((trade) => {
        if (trade.profitLoss != null) {
          if (profitFilter === 'profit') {
            return trade.profitLoss > 0;
          } else if (profitFilter === 'loss') {
            return trade.profitLoss < 0;
          }
        }
        return false;
      });
    }

    // State filter
    if (selectedStates && selectedStates.length > 0) {
      const states = selectedStates.map((option) => option.value);
      filtered = filtered.filter((trade) => states.includes(trade.state));
    }

    // Symbol filter
    if (selectedSymbols && selectedSymbols.length > 0) {
      const selectedValues = selectedSymbols.map((option) => option.value);
      if (!selectedValues.includes('all')) {
        filtered = filtered.filter((trade) => selectedValues.includes(trade.symbol));
      }
    }

    // Sorting
    if (sortColumn) {
      filtered.sort((a, b) => {
        let valueA = a[sortColumn];
        let valueB = b[sortColumn];

        // For dates, convert to timestamps
        if (sortColumn === 'openTime' || sortColumn === 'closeTime') {
          valueA = valueA ? new Date(valueA).getTime() : 0;
          valueB = valueB ? new Date(valueB).getTime() : 0;
        }

        // For profitLoss, handle null values
        if (sortColumn === 'profitLoss') {
          valueA = valueA != null ? valueA : 0;
          valueB = valueB != null ? valueB : 0;
        }

        if (valueA < valueB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredTrades(filtered);
  }, [
    openDateRange,
    closeDateRange,
    profitFilter,
    selectedStates,
    selectedSymbols,
    sortColumn,
    sortOrder,
    trades,
  ]);

  // Handle symbol selection
  const handleSymbolChange = (selectedOptions) => {
    if (selectedOptions) {
      const allSelected = selectedOptions.find((option) => option.value === 'all');
      if (allSelected) {
        setSelectedSymbols(symbolOptions.slice(1));
      } else {
        setSelectedSymbols(selectedOptions);
      }
    } else {
      setSelectedSymbols([]);
    }
  };

  // Handle sorting
  const handleSort = (column) => {
    let order = 'asc';
    if (sortColumn === column && sortOrder === 'asc') {
      order = 'desc';
    }
    setSortColumn(column);
    setSortOrder(order);
  };

  // Handle row expansion
  const handleRowClick = (index) => {
    const currentIndex = expandedRows.indexOf(index);
    const newExpandedRows = [...expandedRows];

    if (currentIndex >= 0) {
      newExpandedRows.splice(currentIndex, 1);
    } else {
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };

  const formatDate = (dateInUTCString) => {
    if (!dateInUTCString) {
      return '';
    }
    const dateInUTC = new Date(dateInUTCString);
    const formattedDate = [
      dateInUTC.getUTCFullYear(),
      String(dateInUTC.getUTCMonth() + 1).padStart(2, '0'),
      String(dateInUTC.getUTCDate()).padStart(2, '0'),
    ].join('-') +
    ' ' +
    [
      String(dateInUTC.getUTCHours()).padStart(2, '0'),
      String(dateInUTC.getUTCMinutes()).padStart(2, '0'),
      String(dateInUTC.getUTCSeconds()).padStart(2, '0'),
    ].join(':');
    return formattedDate;
  };

  // Calculate total profit and loss
  const totalProfitLoss = filteredTrades.reduce((acc, trade) => {
    if (trade.profitLoss != null) {
      return acc + parseFloat(trade.profitLoss);
    }
    return acc;
  }, 0);

  return (
    <div className="trade-table-container">
      <div className="filters-container">
        <div className="filter-header">
          <h2>Trade Filters</h2>
        </div>
        <div className="filters">
          {/* Open Time Range Filter */}
          <div className="filter-item">
            <h3 onClick={() => setOpenDatePickerVisible(!openDatePickerVisible)} className="filter-heading">
              Filter by Open Time {openDatePickerVisible ? '▲' : '▼'}
            </h3>
            {openDatePickerVisible && (
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setOpenDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={openDateRange}
              />
            )}
          </div>

          {/* Close Time Range Filter */}
          <div className="filter-item">
            <h3 onClick={() => setCloseDatePickerVisible(!closeDatePickerVisible)} className="filter-heading">
              Filter by Close Time {closeDatePickerVisible ? '▲' : '▼'}
            </h3>
            {closeDatePickerVisible && (
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setCloseDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={closeDateRange}
              />
            )}
          </div>

          {/* Profitability Filter */}
          <div className="filter-item">
            <h3 className="filter-heading">Filter by Profitability</h3>
            <select
              value={profitFilter}
              onChange={(e) => setProfitFilter(e.target.value)}
              className="filter-select"
            >
              <option value="all">All</option>
              <option value="profit">Profitable Trades</option>
              <option value="loss">Loss Trades</option>
            </select>
          </div>

          {/* State Filter */}
          <div className="filter-item">
            <h3 className="filter-heading">Filter by State</h3>
            <Select
              isMulti
              options={stateOptions}
              onChange={(selectedOptions) => setSelectedStates(selectedOptions)}
              className="filter-select"
            />
          </div>

          {/* Symbol Filter */}
          <div className="filter-item">
            <h3 className="filter-heading">Filter by Symbols</h3>
            <Select
              isMulti
              options={symbolOptions}
              value={selectedSymbols}
              onChange={handleSymbolChange}
              className="filter-select"
            />
          </div>
        </div>
      </div>

      <h2>Trades</h2>
      {filteredTrades.length > 0 ? (
        <>
          <table className="trade-table">
            <thead>
              <tr>
                <th>No</th>
                <th onClick={() => handleSort('symbol')} className="sortable">
                  Symbol {sortColumn === 'symbol' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
                </th>
                <th onClick={() => handleSort('state')} className="sortable">
                  State {sortColumn === 'state' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
                </th>
                <th onClick={() => handleSort('profitLoss')} className="sortable">
                  Profit/Loss {sortColumn === 'profitLoss' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
                </th>
                <th>Long Platform</th>
                <th>Short Platform</th>
                <th onClick={() => handleSort('openTime')} className="sortable">
                  Open Time {sortColumn === 'openTime' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
                </th>
                <th onClick={() => handleSort('closeTime')} className="sortable">
                  Close Time {sortColumn === 'closeTime' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredTrades.map((trade, index) => {
                const profitLossValue =
                  trade.profitLoss != null ? parseFloat(trade.profitLoss) : null;
                const rowClass =
                  profitLossValue != null
                    ? profitLossValue > 0
                      ? 'profit'
                      : profitLossValue < 0
                      ? 'loss'
                      : ''
                    : '';

                const isExpanded = expandedRows.includes(index);

                return (
                  <React.Fragment key={index}>
                    <tr
                      className={`${rowClass} expandable-row`}
                      onClick={() => handleRowClick(index)}
                    >
                      <td>{index + 1}</td>
                      <td>{trade.symbol}</td>
                      <td>{trade.state}</td>
                      <td>
                        {profitLossValue != null
                          ? profitLossValue.toFixed(8)
                          : 'N/A'}
                      </td>
                      <td>{trade.longPlatform}</td>
                      <td>{trade.shortPlatform}</td>
                      <td>
                        {trade.openTime
                          ? formatDate(trade.openTime)
                          : 'N/A'}
                      </td>
                      <td>
                        {trade.closeTime
                          ? formatDate(trade.closeTime)
                          : 'N/A'}
                      </td>
                    </tr>
                    {isExpanded && (
                      <tr className="expanded-row">
                        <td colSpan="8">
                          <div className="secondary-details">
                            <h4>Secondary Details</h4>
                            <table className="secondary-table">
                              <tbody>
                                <tr>
                                  <td>Long Open Slippage:</td>
                                  <td>
                                    {trade.longOpenSlippage != null
                                      ? parseFloat(trade.longOpenSlippage).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Long Close Slippage:</td>
                                  <td>
                                    {trade.longCloseSlippage != null
                                      ? parseFloat(trade.longCloseSlippage).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Short Open Slippage:</td>
                                  <td>
                                    {trade.shortOpenSlippage != null
                                      ? parseFloat(trade.shortOpenSlippage).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Short Close Slippage:</td>
                                  <td>
                                    {trade.shortCloseSlippage != null
                                      ? parseFloat(trade.shortCloseSlippage).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Open Price Diff Rate:</td>
                                  <td>
                                    {trade.open_price_diff_rate != null
                                      ? parseFloat(trade.open_price_diff_rate).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Close Price Diff Rate:</td>
                                  <td>
                                    {trade.close_price_diff_rate != null
                                      ? parseFloat(trade.close_price_diff_rate).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Long Signal Open Price:</td>
                                  <td>
                                    {trade.long_signal_open_price != null
                                      ? parseFloat(trade.long_signal_open_price).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Long Open Price:</td>
                                  <td>
                                    {trade.long_open_price != null
                                      ? parseFloat(trade.long_open_price).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Long Signal Close Price:</td>
                                  <td>
                                    {trade.long_signal_close_price != null
                                      ? parseFloat(trade.long_signal_close_price).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Long Close Price:</td>
                                  <td>
                                    {trade.long_close_price != null
                                      ? parseFloat(trade.long_close_price).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Short Signal Open Price:</td>
                                  <td>
                                    {trade.short_signal_open_price != null
                                      ? parseFloat(trade.short_signal_open_price).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Short Open Price:</td>
                                  <td>
                                    {trade.short_open_price != null
                                      ? parseFloat(trade.short_open_price).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Short Signal Close Price:</td>
                                  <td>
                                    {trade.short_signal_close_price != null
                                      ? parseFloat(trade.short_signal_close_price).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Short Close Price:</td>
                                  <td>
                                    {trade.short_close_price != null
                                      ? parseFloat(trade.short_close_price).toFixed(8)
                                      : 'N/A'}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <div className="total-profit-loss">
            <strong>Total Profit/Loss:</strong> {totalProfitLoss.toFixed(8)}
          </div>
        </>
      ) : (
        <p>No trades found.</p>
      )}
    </div>
  );
};

export default TradeTable;